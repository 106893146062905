.Home {
  .Home-Container {
    padding-top: 5rem;
  }

  .Home-Card {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%),
      url(/assets/img/Atlanta.jpg);
    background-attachment: fixed;
    background-position: center, center;
    background-size: cover, 50%;

    &:hover {
      opacity: 0.95;
    }
  }

  .Home-CardContent.Home-CardContent {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .Home-large-icon {
    width: 60px;
    height: 60px;
    fill: #00acc1;
  }

  a {
    &,
    &:active,
    &:focus,
    &:visited,
    &:hover {
      text-decoration: none;
    }
  }

  .Home-page-name {
    color: white;
  }
}
